@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.wrp {
  position: relative;
  width: 270px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin: 10px;
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;
}

.newCard {
  background-color: #ee57c4;
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 12px;
  line-height: 14px;
  padding: 1px 3px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 500;
  border-radius: 2px;
}

.cardHeader {
  height: 130px;
  width: 100%;
  user-select: none;

  > img {
    opacity: 0.7;
    user-select: none;
  }
}

.cover {
  height: 129px;
}

.coverImage {
  user-select: none;
}

.iconWrp {
  display: table;
  position: absolute;
  width: 63px;
  height: 63px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: #fff;
  top: 92px;
  left: 20px;
}

.icon {
  width: 26px;
  user-select: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.description {
  text-align: left;
  padding: 36px 20px 0;
  min-height: 230px;
  box-sizing: border-box;

  > * {
    margin: 0;
  }

  > p {
    font-family: $fontFamily;
    color: $baseTextColor;
    font-size: 14px;
    line-height: 20px;
  }

  > h3 {
    font-family: $titleFontFamily;
    font-size: 24px;
    line-height: 30px;
    font-weight: bold;
    margin-bottom: 10px;
  }
}

.buttons {
  line-height: 16px;
  padding: 20px;

  &:after {
    display: block;
    content: '';
    clear: both;
  }
}

.learnMore {
  font-weight: 500;
  user-select: none;
}

