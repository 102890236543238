@import "@dealroadshow/uikit/core/styles/variables/_common.scss";
@import "@dealroadshow/uikit/core/styles/variables/colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.successContainer {
  width: 100%;
}

.successIcon {
  display: block;
  margin: 100px auto 20px;
  width: 77px;
  height: 72px;
}

.successHeader {
  font-family: $titleFontFamily;
  font-size: $h2FontSize;
  font-weight: bold;
  color: $successColor;
  text-align: center;
}

.successText {
  font-family: $fontFamily;
  font-size: 18px;
  color: $baseTextColor;
  text-align: center;
  line-height: 21px;
  padding-bottom: 50px;
}
