@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/components/Clearfix/clearfix.scss";

.wrp {
  margin: 20px auto;
  max-width: 1200px;

  @include clearfix;

  @include mqMaxWidth($screenL) {
    margin: auto;
    width: 878px;
  }

  @include mqMaxWidth(1110px) {
    margin: auto;
    width: 585px;
  }

  @include mqMaxWidth($screenS) {
    margin: auto;
    width: 300px;
  }
}

.spinnerWrapper {
  margin: 60px auto;
  width: 100%;
}

.header {
  margin: 15px 10px 0;
  font-family: $titleFontFamily;
  letter-spacing: -0.02em;
  font-weight: bold;
  color: $titleColor;
  font-size: 48px;
  line-height: 55px;

  @include mqMaxWidth($screenS) {
    font-size: 30px;
    line-height: 33px;
  }
}

.text {
  max-width: 850px;
  font-family: $fontFamily;
  font-size: 14px;
  line-height: 18px;
  color: $baseTextColor;
  margin: 15px 10px;
}

.btnWrapper {
  margin: 24px 10px 50px;

  @include mqMaxWidth($screenS) {
    margin: 15px 10px 25px;
  }
}
