@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.switch {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 10px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #dbdbdb;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: -4px;
  top: -3px;
  background-color: #c0c0c0;
  transition: 0.4s;
}

.slider:after {
  content: "OFF";
  position: relative;
  right: 35px;
  bottom: 4px;
  font-size: 10px;
  font-family: $fontFamily;
}

input:checked + .slider:after {
  content: "ON";
}

input:checked + .slider {
  background-color: #dae5f5;
}

input:checked + .slider.round:before {
  background: #4a76b6;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  transform: translateX(24px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.activated {
  float: right;
  font-size: 10px;
  color: $secondaryColor;
  margin-top: 3px;
}

.reloadIcon {
  fill: $actionColor;
  position: relative;
  margin-left: 10px;
}

.statusTitle {
  color: #c0c0c0;
  float: right;
  height: 16px;
  vertical-align: bottom;
  padding-top: 3px;
}

.statusToggle {
  float: right;
  margin-left: 10px;
}

.statusToggleActive {
  color: #8c8c8c;
}

.noAccess {
  color: $cancelColor;
}

.notAvailable {
  float: right;
  font-size: 10px;
  color: $secondaryColor;
  position: relative;
  bottom: 3px;

  > a {
    position: relative;
    top: 3px;
  }
}

.spinnerOverlay {
  background-color: rgba(255, 255, 255, 0.9);
}

.refreshBtnWrp {
  width: 16px;
  height: 16px;
  float: right;
  margin-left: 10px;
}

.refreshBtn {
  svg {
    fill: $actionColor;
  }
}
